import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const clientImgA = data.clients.edges.find(
    element => element.node.name === "client-logo-ketchum-pr"
  )
  const clientImgB = data.clients.edges.find(
    element => element.node.name === "client-logo-rhs"
  )
  const clientImgC = data.clients.edges.find(
    element => element.node.name === "client-logo-huddart-consulting"
  )
  const clientImgD = data.clients.edges.find(
    element => element.node.name === "client-logo-yoga"
  )

  const profileIris = data.profiles.edges.find(
    element => element.node.name === "profile-iris-gibson"
  )

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About" />
      <Image
        fluid={data.mast.childImageSharp.fluid}
        alt="Me"
        style={{
          maxWidth: `100%`,
          marginBottom: rhythm(1 / 2),
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: 0,
        }}
      />
      <div style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <section>
          <h1 style={{ textAlign: `center` }}>About</h1>

          <div className="grid align-center">
            <div className="col col-6">
              <span className="banner">
                Video can inform, inspire and entertain
              </span>
            </div>
            <div className="col col-6">
              <p>
                Do you have a unique and compelling story to tell? Do you want
                to harness the power of video to do so? You are in the right
                place.
              </p>
              <p>
                Branding is so much more than just a nice font and a logo - it
                is more than a few colours and a savvy name. A good brand evokes
                feeling, and allows people to connect, and there are few more
                powerful ways to do this than with video.
              </p>
              <p>
                A well-crafted brand film allows you to tell your brand story
                with eloquence and creativity. It invites your audience in to
                see who you are and what value your brand gives them. People can
                connect with your message and understand a story that goes
                beyond just words.
              </p>
            </div>
          </div>
        </section>
      </div>

      <div style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <section>
          <div className="grid align-center">
            <div className="col col-6">
              <h3 style={{ marginTop: `0` }}>Hi there,</h3>
              <p>
                I’m Iris, a freelance filmmaker &amp; video content creator with
                over 15 years of experience in the video production industry.
              </p>
              <p>
                Originally from Vienna I’m partial to good cake and coffee. I
                live in Kent with my husband and our lurcher Willow - only 35
                minutes from London which makes it the ideal base for any video
                content creation, video editing projects and brand video shoots
                all over the UK and Europe.
              </p>
              <p>
                I’ve worked with clients like Habitat, The Royal Horticultural
                Society, Made.com, Hewlett-Packard and IBM and filmed high
                profile celebrities like Michel Roux Jr and Russell Brand.
              </p>
              <p>
                I know how to tell a story well, produce engaging content and
                love being involved in the whole video production process from
                start to finish. Not only am I skilled to manage the complete
                creative workflow for a wide range of audio-visual content but I
                also have an excellent sense of pace and timing, as well as the
                ability to visualise the written/spoken word.
              </p>
            </div>
            <div className="col col-6">
              <Image
                fluid={data.mastIris.childImageSharp.fluid}
                alt="Me"
                style={{
                  maxWidth: `460px`,
                  marginBottom: rhythm(1 / 2),
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  marginTop: 0,
                }}
              />
            </div>
          </div>

          <div className="grid align-center">
            <div className="col col-6">
              <span className="banner">
                High-quality results on message and on budget
              </span>
            </div>
            <div className="col col-6">
              <p>
                Video is a diverse and adaptable art form. So whether you are a
                heritage brand looking to bring your company’s history to life,
                you sell delicious food and want to proudly showcase your
                quality produce, or even if you are an artisan who would like to
                invite potential customers into your studio to unveil how your
                exquisite pieces are being made - video is the perfect medium!
              </p>
              <p>
                I thrive on telling people’s stories through video, and work
                closely with clients to deliver high-quality results, with a
                powerful message, on budget and on time.
              </p>
            </div>
          </div>
          <div style={{ textAlign: `center`, marginBottom: `2rem` }}>
            <Link to={`/services`} className="button">
              Services
            </Link>
          </div>

          <div className="grid align-center">
            <div className="col col-6">
              <Image
                fluid={data.videoProdKent.childImageSharp.fluid}
                alt="Me"
                style={{
                  maxWidth: `460px`,
                  marginBottom: rhythm(1 / 2),
                  marginLeft: `auto`,
                  marginRight: `auto`,
                }}
              />
            </div>
            <div className="col col-6">
              <span className="banner">
                Video offers a unique and personal brand experience
              </span>
              <p>Let me tell your story!</p>
              <p>
                Corporate doesn't have to mean boring and small business films
                don't have to look low-budget - I create beautiful video content
                for big and small brands alike.
              </p>
              <p>
                Crafting a film from scratch and 'seeing an idea coming
                together' thrills me and I love tailoring bespoke video
                solutions for each client.
              </p>
              <p>
                Crafted Films offers a streamlined service for everyone who
                wants something more personal than they'd get from a bigger
                agency - video production with feeling and a keen eye for detail
                to bring your story to life.
              </p>
            </div>
          </div>

          <div className="grid align-center">
            <div className="col col-4">
              <span className="banner">
                It evokes emotion and can easily be shared
              </span>
            </div>
            <div className="col col-4">
              <p>
                Whether you are launching a new service or extending your
                product range, video is the perfect medium to drum up some
                interest and promote your latest venture to potential customers
                and clients.
              </p>
              <p>
                You have invested in a professional wesite and your online
                presence is beautifully thought through but you are missing the
                final piece of the puzzle - let's explore how video can elevate
                your brand!
              </p>
            </div>
            <div className="col col-4">
              <Image
                fluid={data.freelanceFilmIris.childImageSharp.fluid}
                alt="Me"
                style={{
                  maxWidth: `460px`,
                  marginBottom: rhythm(1 / 2),
                  marginLeft: `auto`,
                  marginRight: `auto`,
                }}
              />
            </div>
          </div>
          <div style={{ textAlign: `center`, marginBottom: `5rem` }}>
            <Link to={`/contact`} className="button">
              Work with me
            </Link>
          </div>
        </section>

        <section>
          <h1 style={{ textAlign: `center` }}>
            Kind words from previous clients
          </h1>

          <div className="grid">
            <div className="col col-6">
              {clientImgA ? (
                <Image
                  fixed={clientImgA.node.childImageSharp.fixed}
                  style={{ display: `flex`, margin: `1rem auto` }}
                />
              ) : (
                ""
              )}
              <p>
                'I can highly recommend Iris for her high degree of
                professionalism on every project we have partnered on. She has a
                strong understanding of storytelling and is very organised when
                it comes to content production and getting key logistical
                elements in place.
              </p>
              <p>
                She also has great attention to detail and when it comes to the
                final “on-air” product - manages to ensure that messages are
                told in a clever, visual manner. Having spent long periods of
                time on the road with Iris doing overseas jobs, she is also
                great company and calm at times of challenge. I cannot recommend
                her highly enough.'
              </p>
              <p>
                <strong>
                  RICHARD GRIFFITHS <br />
                  Director, Strategic Communications at Ketchum
                </strong>
              </p>
            </div>
            <div className="col col-6">
              {clientImgB ? (
                <Image
                  fixed={clientImgB.node.childImageSharp.fixed}
                  style={{ display: `flex`, margin: `1rem auto` }}
                />
              ) : (
                ""
              )}
              <p>
                'We worked with Iris on several short films used to promote our
                community gardening programmes, and to support our community
                network. She was a delight to work with.
              </p>
              <p>
                From the early conceptual stages to the finished products, Iris
                was insightful, creative, efficient and highly professional –
                with a keen eye on ensuring that we created what we set out to.
                I highly recommend her.'
              </p>
              <p>
                <strong>
                  EMILY BRAHAM <br />
                  Senior Communications Coordinator, RHS
                </strong>
              </p>
            </div>
          </div>

          <div className="grid">
            <div className="col col-6">
              {clientImgC ? (
                <Image
                  fixed={clientImgC.node.childImageSharp.fixed}
                  style={{ display: `flex`, margin: `1rem auto` }}
                />
              ) : (
                ""
              )}
              <p>
                'I can't stress how important it is to find an expert like Iris
                to guide you through this. No matter how amazing you think your
                iPhone is, your video isn't going to turn out well unless you
                have all the components correct.
              </p>
              <p>
                We all learn this lesson at some point. Save yourself from
                learning this the hard way and get Iris's help!'
              </p>
              <p>
                <strong>
                  KRISTINA HUDDART <br />
                  Marketing technology consultant
                </strong>
              </p>
            </div>
            <div className="col col-6">
              {clientImgD ? (
                <Image
                  fixed={clientImgD.node.childImageSharp.fixed}
                  style={{ display: `flex`, margin: `1rem auto` }}
                />
              ) : (
                ""
              )}
              <p>
                'Working with Iris was such a dream. She is so hard working and
                knowledgeable. She designed an amazing opening sequence to my
                yoga videos only using old videos I had filmed on my phone
                (because of COVID we couldn't meet in person).
              </p>
              <p>
                It looked extremely professional and I am thrilled with the
                result. On top of this she edited pretty much every video on my
                new app really efficiently and I am so happy with the final
                videos. I can't wait to meet Iris in person and work more
                together in future.'
              </p>
              <p>
                <strong>
                  HANNAH BARRETT <br />
                  Yoga teacher
                </strong>
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className="grid justify-center">
            <div className="col col-4">
              {profileIris ? (
                <Image
                  fluid={profileIris.node.childImageSharp.fluid}
                  style={{
                    maxWidth: `460px`,
                    marginBottom: rhythm(1 / 2),
                    marginLeft: `auto`,
                    marginRight: `auto`,
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
        <div style={{ textAlign: `center` }}>
          <Link to={`/portfolio`} className="button">
            Crafted Films
            <br /> Portfolio
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    mast: file(absolutePath: { regex: "/mast-about.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mastIris: file(absolutePath: { regex: "/mast-about-iris.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    freelanceFilmIris: file(
      absolutePath: { regex: "/iris-freelance-filmmaker.jpg/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 660, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    videoProdKent: file(
      absolutePath: { regex: "/about-video-prod-kent.jpg/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    avatar: file(absolutePath: { regex: "/profile-out-and-about.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    clients: allFile(
      filter: {
        extension: { regex: "/(png)/" }
        absolutePath: { regex: "/(logos/client)/" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fixed(height: 90) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
    profiles: allFile(
      filter: {
        extension: { regex: "/(png)|(jpg)/" }
        absolutePath: { regex: "/(profiles/profile)/" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 660, maxHeight: 800, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
